import React, { useEffect, useState } from "react";
import "../styles/Follow.scss";

const Follow = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 460);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 460);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="additional">
      <section className="follow wrapper" id="instagram">
        <div className="follow__bg">
          <img src={process.env.PUBLIC_URL + "/img/3.png"} alt="Logo" />
        </div>
        <div className="follow__content">
          <div className="follow__info">
            <h3>Підписуйтесь на наш Instagram</h3>
            {!isMobileView && (
              <p>
                У нашому профілі ми ділимося результатами роботи, показуємо "до і після" наших процедур, розповідаємо
                про сучасні методики та даємо поради щодо догляду за собою.
              </p>
            )}
            {isMobileView && (
              <p>
                У нашому профілі ми показуємо "до і після" наших процедур, розповідаємо та даємо поради щодо догляду за
                собою.
              </p>
            )}
          </div>
          <a href="https://www.instagram.com/dr.zueva.angelina?igsh=emd5NHV6aTVlbmdq" className="follow__link">
            <img src={process.env.PUBLIC_URL + "/icons/instagramBigIcon.svg"} alt="Logo" />
            <h4>dr.zueva.angelina</h4>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Follow;
