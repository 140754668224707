import React, { useState, useEffect } from "react";
import "../styles/Procedures.scss";
import { collection, getDocs } from "firebase/firestore";
import db from "../firebase";

const Procedures = () => {
  const [expandedService, setExpandedService] = useState<string | null>(null);

  const toggleService = (serviceName: string) => {
    setExpandedService((prev) => (prev === serviceName ? null : serviceName));
  };

  const [servicesCategory, setServicesCategory] = useState<any>([]);
  const [allServices, setAllServices] = useState<any>([]);

  useEffect(() => {
    const colRef = collection(db, "services");
    getDocs(colRef)
      .then((snapshot) => {
        let tech_type: any = [];
        snapshot.docs.forEach((doc) => {
          tech_type.push({ ...doc.data(), id: doc.id });
        });
        setServicesCategory(tech_type);
        console.log(tech_type);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    const colRef = collection(db, "services_data");
    getDocs(colRef)
      .then((snapshot) => {
        let tech_type: any = [];
        snapshot.docs.forEach((doc) => {
          tech_type.push({ ...doc.data(), id: doc.id });
        });
        setAllServices(tech_type);
        console.log(tech_type);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="additional">
      <div className="service wrapper" id="products">
        {servicesCategory.map((category: any) => (
          <div key={category.type} className="service__category">
            <h2>{category.category_name}</h2>
            <ul className="service__list">
              {allServices
                .filter((service: any) => service.type === category.type)
                .map((service: any) => (
                  <li key={service.name} className="service__item">
                    <div className="service__header" onClick={() => toggleService(service.name)}>
                      <h3>{service.name}</h3>
                      <span className={`chevron ${expandedService === service.name ? "open" : ""}`}>
                        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.0564 0.886871C21.5555 0.433179 22.3177 0.433359 22.8166 0.887285C23.3869 1.40628 23.3868 2.30353 22.8163 2.82236L13.2889 11.4866C13.1191 11.6419 12.9173 11.7651 12.695 11.8492C12.4728 11.9333 12.2344 11.9766 11.9936 11.9766C11.7528 11.9766 11.5144 11.9333 11.2922 11.8492C11.0699 11.7651 10.8681 11.6419 10.6984 11.4866L1.16585 2.82224C0.595255 2.3036 0.595216 1.40631 1.16577 0.88763C1.66433 0.434387 2.42573 0.434352 2.92434 0.887549L10.6455 7.90551C11.4084 8.59888 12.5733 8.59883 13.3361 7.90538L21.0564 0.886871Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                    {expandedService === service.name && (
                      <div className="service__more">
                        <div className="service__details">
                          <div className="service__left">
                            {service.description && <p>{service.description}</p>}
                            <table className="service__table">
                              <tbody>
                                {service.areas.map((area: any) => (
                                  <tr key={area.name}>
                                    <td>{area.name}</td>
                                    <td>{area.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {service.image && (
                            <div className="service__right">
                              <img src={process.env.PUBLIC_URL + `/service/${service.image}`} alt={service.name} />
                            </div>
                          )}
                        </div>
                        {service.additional && (
                          <div className="service__additional">
                            <h4>{service.additional.name}</h4>
                            <table className="service__table">
                              <tbody>
                                {service.additional.areas.map((area: any) => (
                                  <tr key={area.name}>
                                    <td>{area.name}</td>
                                    <td>{area.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Procedures;
