import React, { useEffect } from "react";
import Main from "./Main";
import About from "./About";
import Procedures from "./Procedures";
import Follow from "./Follow";
import Gallery from "./Gallery";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Main />
      <About />
      <Procedures />
      <Follow />
      <Gallery />
    </>
  );
};

export default Home;
