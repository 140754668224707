import React, { useState } from "react";
import "../styles/About.scss";

const About = () => {
  const [isModal, setIsModal] = useState(false);

  return (
    <div className="additional">
      {isModal && (
        <div className="additional_modal">
          <div className="additional_modal_overlay" onClick={() => setIsModal(!isModal)}></div>
          <iframe
            title="AdditionalModal"
            src="https://bookon.ua/lite/dr.zueva.angelina"
            height={"100vh"}
            width={"350px"}
          ></iframe>
        </div>
      )}
      <section className="about wrapper" id="about">
        <div className="about__info">
          <h2>Ласкаво просимо до нашої клініки естетичної медицини</h2>
          <p>
            Ми пропонуємо індивідуальний підхід до кожного клієнта, щоб створити неповторний образ та підкреслити
            природну красу.
          </p>
          <p>
            Ми дбаємо не лише про зовнішній вигляд, а й про ваше здоров'я, надаючи процедури, які дарують не лише красу,
            а й гарне самопочуття.
          </p>
          <p>
            В нашій клініці ви можете знайти не тільки великий перелік послуг, але ще й цікавих людей завдяки нашому
            ком'юніті
          </p>
          <p>
            <span>
              <b>Наша клініка має ліцензію МОЗ, </b>
            </span>
            що підтверджує наше право на надання косметологічних послуг.
          </p>
          <button className="btn" onClick={() => setIsModal(!isModal)}>
            Послуги
          </button>
        </div>
        <div className="about__pic">
          <img src={process.env.PUBLIC_URL + "/img/2.png"} alt="Logo" />
        </div>
      </section>
    </div>
  );
};

export default About;
