import React, { useEffect } from 'react';
import "../styles/Contract.scss";

const Contract = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="additional">
        <section className='contract wrapper'>
            <h3>Правила відвідування клініки</h3>
            <p>
                Шановні пацієнти, ми завжди дуже раді Вам у клініці, але з метою забезпечення безпеки всіх відвідувачів та співробітників клініки просимо дотримуватись певних правил, описаних нижче.
                <br />
                <br />
                З повним текстом публічного Договору (публічною офертою) можна ознайомитись у клініці Dr.Zueva на рецепції та/або в Куточку (папці) споживача.
            </p>
            <div className="contract__bloc">
                <div className="contract__side">
                    <p>Наведена інформація є офіційною пропозицією (офертою) клініки Dr.Zueva будь-якій фізичній особі, яка бажає укласти договір (письмовий чи усний) надання послуг (тобто отримати послугу). Відповідно до ст.ст. 633, 634, 641 Цивільного кодексу України цей Договір є публічним Договором (публічною офертою), що містить всі істотні умови надання Виконавцем <b>медичних</b> послуг та пропонує необмеженому колу фізичних осіб (Пацієнтам) отримати <b>медичні</b> послуги на умовах, визначених цим Договором.</p>
                    <p>Виконавець надає <b>медичні</b> послуги на підставі ліцензії на медичну практику 10 травня 2024 р. No817, виданої наказом Міністерства охорони здоров’я України.</p>
                    <p>Перелік дозволених спеціальностей Виконавця: лікарські спеціальності – <b>дерматовенерологія, ендокринологія, дієтологія, ультразвукова діагностика;</b> спеціальності молодших спеціалістів з медичною освітою: <b>сестринська справа.</b></p>
                    <ul>
                        <li>Послуги надаються в медичному кабінеті Виконавця відповідно до Правил, з використанням сертифікованого обладнання та дозволених до використання медичних виробів та матеріалів.</li>
                        <li>Послуги надаються за попереднім записом, який здійснюється за телефоном або при особистому зверненні Пацієнта. Надання Послуг без попереднього запису можливо виключно у випадках відсутності попереднього запису на цей час інших Пацієнтів. Дата та час надання кожної Послуги погоджується Виконавцем та Пацієнтом в усній або письмовій (шляхом підписання графіка відвідувань) формі.</li>
                        <li>Дату та час надання кожної Послуги може бути змінено з ініціативи Пацієнта до настання строку надання такої Послуги. У разі запізнення Пацієнт зобов’язаний заздалегідь попередити про це лікуючого лікаря. У разі запізнення, що призвело до зміщення графіку прийому, з Пацієнтом узгоджується інший час візиту.</li>
                        <li>Вартість Послуг, що надаються за цим Договором, визначається відповідно до їх обсягу та затверджених Виконавцем тарифів на Послуги. Тарифи на Послуги мають юридичну силу угоди про узгоджену ціну. Попередня вартість Послуг зазначається в Плані лікування та не включає вартість лікування прихованих патологій, які можуть бути виявлені в процесі лікування. Ціни, вказані в Плані лікування, дійсні протягом строку, зазначеного в Плані лікування. Після спливу цього строку вартість Послуг визначається виходячи з діючих на момент надання Послуги тарифів Виконавця.</li>
                        <li>Приймання-передача наданих Послуг здійснюється в усній формі чи на вимогу однієї зі Сторін шляхом оформлення Акту наданих послуг (далі – Акт), який складається Виконавцем в двох примірниках та надається Пацієнту для підписання. Пацієнт зобов’язаний підписати обидва примірника Акту або надати письмову мотивовану відмову від його підписання.</li>
                    </ul>
                </div>
                <div className="contract__side">
                    <ul>
                        <li>Послуги надаються медичними працівниками Виконавця, які мають необхідну спеціальну освіту і відповідають єдиним кваліфікаційним вимогам відповідно до законодавства України. Певні види Послуг можуть надаватися різними медичними спеціалістами Виконавця. Послуги надаються відповідно до клінічних протоколів та стандартів медичної допомоги (медичних стандартів), затверджених МОЗ.</li>
                        <li>Виконавець гарантує якість наданих Послуг. Гарантія надається на ті види Послуг, перелік яких затверджений Виконавцем. Детальна інформація про гарантійні зобов’язання Виконавця міститься в Куточку (папці) споживача.</li>
                        <li>У випадках, коли при погодженні Плану лікування Пацієнт наполягає на застосуванні медичних втручань, позитивні результати яких дуже сумнівні чи ефект від їх застосування не буде стійким, а також коли Пацієнт бажає задовольнити нестандартні естетичні уподобання, гарантійні зобов’язання не оформлюються, а Пацієнт надає Виконавцю письмову заяву про відмову від гарантійних зобов'язань та інформування про можливі негативні наслідки.</li>
                        <li>На виконання Закону України "Про захист персональних даних" Виконавець повідомляє Пацієнта про здійснення обробки його персональних даних під час виконання даного Договору. Володільцем персональних даних є Виконавець.</li>
                        <li>Персональні дані збираються з метою охорони здоров’я, встановлення медичного діагнозу, для забезпечення піклування чи лікування або надання медичних послуг (п. 6 ч. 2 ст. 7 Закону України "Про захист персональних даних").</li>
                        <li>Виконавець бере на себе зобов’язання щодо дотримання конфіденційності інформації, отриманої під час виконання даного Договору. Виконавець декларує, що зобов’язання щодо конфіденційності, передбачені цим розділом, мають безстроковий характер і зберігають свою чинність після закінчення строку дії цього Договору.</li>
                        <li>Пацієнт відповідає за достовірність наданої інформації щодо свого здоров’я, виконання рекомендацій лікаря, своєчасну оплату наданих Послуг. Виконавець відповідає за якість та безпеку наданих Послуг.</li>
                        <li>У разі виникнення спорів або розбіжностей Сторони зобов’язуються вирішувати їх шляхом взаємних переговорів та консультацій. У випадку, коли Пацієнт незадоволений якістю наданих йому медичних послуг, сервісним рівнем обслуговування, вважає, що його права як пацієнта і споживача були порушені, він має право звернутись зі скаргою до адміністрації Виконавця.</li>
                    </ul>
                    <p>Зміни до цього Договору вносяться Виконавцем в односторонньому порядку та оприлюднюються на паперових носіях в Закладі Виконавця - на рецепції та/або в Куточку (папці) споживача. </p>
                </div>
            </div>
        </section>
        </div>
    );
};

export default Contract;