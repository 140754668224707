import React, { useState } from "react";
import "../styles/Header.scss";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      {isModal && (
        <div className="header_modal">
          <div className="header_modal_overlay" onClick={() => setIsModal(!isModal)}></div>
          <iframe
            title="HeaderModal"
            src="https://bookon.ua/lite/dr.zueva.angelina"
            height={"100vh"}
            width={"350px"}
          ></iframe>
        </div>
      )}

      <div className="additional">
        <div className="header__container wrapper">
          <Link to="/" className="header__logo">
            <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Logo" />
          </Link>

          <nav className={`header__nav ${isMenuOpen ? "active" : ""}`}>
            <ul className="header__ul">
              <li>
                <Link className="header__li" to="/#about" onClick={closeMenu}>
                  Про нас
                </Link>
              </li>
              <li>
                <Link className="header__li" to="/#products" onClick={closeMenu}>
                  Послуги
                </Link>
              </li>
              <li>
                <Link className="header__li" to="/#instagram" onClick={closeMenu}>
                  Instagram
                </Link>
              </li>
              <li>
                <Link className="header__li" to="/#pictures" onClick={closeMenu}>
                  Галерея
                </Link>
              </li>
              <li>
                <Link className="header__li" to="/#contacts" onClick={closeMenu}>
                  Контакти
                </Link>
              </li>
            </ul>
          </nav>

          <div className="header__controls">
            <button className="btn" onClick={() => setIsModal(!isModal)}>
              Записатися на процедуру
            </button>
            <div className={`header__burger ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className={`header__overlay ${isMenuOpen ? "visible" : ""}`} onClick={toggleMenu}></div>
      </div>
    </header>
  );
};

export default Header;
