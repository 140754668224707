import React from 'react';
import './styles/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contract from './components/Contract';
import ScrollToHashElement from './components/ScrollToHashElement';

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToHashElement />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contract" element={<Contract />} />
        </Routes>
      <Footer />
    </div>
  );
};

export default App;
