import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import ".env";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDf-GfnHoFbyyz6ukzaUfDma8AciELwXP0",
  authDomain: "drzueva-fad58.firebaseapp.com",
  projectId: "drzueva-fad58",
  storageBucket: "drzueva-fad58.appspot.com",
  messagingSenderId: "757168196543",
  appId: "1:757168196543:web:f29daf1e61c23c5cb56f0a",
});

const db = getFirestore(firebaseApp);

export default db;