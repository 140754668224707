import React, { useEffect, useState } from "react";
import "../styles/Main.scss";

const Main = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 460);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 460);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="main">
      {isModal && (
        <div className="additional_modal">
          <div className="additional_modal_overlay" onClick={() => setIsModal(!isModal)}></div>
          <iframe
            title="AdditionalModal"
            src="https://bookon.ua/lite/dr.zueva.angelina"
            height={"100vh"}
            width={"350px"}
          ></iframe>
        </div>
      )}
      <img className="main__img" src={process.env.PUBLIC_URL + "/img/1.png"} alt="Logo" />
      <div className="additional">
        <div className="main__container wrapper">
          <h1>Клініка естетичної медицини</h1>
          <p>
            Ін'єкційна косметологія, апаратна косметологія, доглядові процедури
            <br />
            <br />
            <span>
              <b>Комʼюніті дівчат, натхненних красою</b>
            </span>
          </p>
          <div className="main__desc">
            <button className="btn" onClick={() => setIsModal(!isModal)}>
              Записатися на процедуру
            </button>
            {!isMobileView && (
              <p>
                <span>
                  <b>Наша клініка має ліцензію </b>
                </span>
                Міністерства Охорони Здоров'я України, що підтверджує високий рівень якості та безпеки послуг
              </p>
            )}
            {isMobileView && (
              <p>
                <span>
                  <b>Наша клініка має ліцензію </b>
                </span>
                Міністерства Охорони Здоров'я
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
