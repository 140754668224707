import React, { useEffect, useState } from "react";
import "../styles/Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="footer">
      <div className="additional">
        {!isMobileView && (
          <div className="footer__links wrapper desktop">
            <div className="footer__location desktop">
              <div className="footer__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.0900797110435!2d30.523333076688143!3d50.441443971590395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce555f32f885%3A0xa7efc9f059312bfc!2z0LLRg9C70LjRhtGPINCU0LDRgNCy0ZbQvdCwLCA1LCDQmtC40ZfQsiwgMDIwMDA!5e1!3m2!1suk!2sua!4v1728919689779!5m2!1suk!2sua"
                  width="330"
                  height="318"
                  style={{ border: "2px solid #000", borderRadius: "5px" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="footer__contacts desktop">
                <a className="footer__phone" href="tel:+380000000000">
                  +38 000 000 00 00
                </a>
                <div className="footer__address">
                  <img src={process.env.PUBLIC_URL + "/icons/locationIcon.svg"} alt="Location" />
                  <a href="https://maps.app.goo.gl/KEsYLTKPvpCDsW9j9" target="_blank" rel="noopener noreferrer">
                    <p>вул. Дарвіна, 5</p>
                  </a>
                </div>
                <div className="footer__time">
                  <p>Час роботи:</p>
                  <p>Вівторок - Субота</p>
                  <p>10:00 - 20:30</p>
                </div>
              </div>
            </div>
            <div className="footer__logo">
              <img src={process.env.PUBLIC_URL + "logoBig.png"} alt="Logo" />
            </div>
          </div>
        )}
        {isMobileView && (
          <div className="footer__links wrapper mobile">
            <div className="footer__location mobile">
              <div className="footer__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.0900797110435!2d30.523333076688143!3d50.441443971590395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce555f32f885%3A0xa7efc9f059312bfc!2z0LLRg9C70LjRhtGPINCU0LDRgNCy0ZbQvdCwLCA1LCDQmtC40ZfQsiwgMDIwMDA!5e1!3m2!1suk!2sua!4v1728919689779!5m2!1suk!2sua"
                  width="330"
                  height="318"
                  style={{ border: "2px solid #000", borderRadius: "5px" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="footer__contacts-tablet">
                <a className="footer__phone" href="tel:+380000000000">
                  +38 000 000 00 00
                </a>
                <div className="footer__address">
                  <img src={process.env.PUBLIC_URL + "/icons/locationIcon.svg"} alt="Location" />
                  <a href="https://maps.app.goo.gl/KEsYLTKPvpCDsW9j9" target="_blank" rel="noopener noreferrer">
                    <p>вул. Дарвіна, 5</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer__contacts mobile">
              <div className="footer__logo">
                <img src={process.env.PUBLIC_URL + "logoBig.png"} alt="Logo" />
              </div>
              <div className="footer__time">
                <p>Час роботи:</p>
                <p>Вівторок - Субота</p>
                <p>10:00 - 20:30</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="footer__bottom">
        <div className="additional">
          <div className="footer__links-bottom wrapper">
            <div className="footer__socials" id="contacts">
              <a
                href="https://www.instagram.com/dr.zueva.angelina?igsh=emd5NHV6aTVlbmdq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={process.env.PUBLIC_URL + "/icons/instaWhiteIcon.svg"} alt="Socials" />
              </a>
              <img src={process.env.PUBLIC_URL + "/icons/tgWhiteIcon.svg"} alt="Socials" />
              <img src={process.env.PUBLIC_URL + "/icons/waWhiteIcon.svg"} alt="Socials" />
              <img src={process.env.PUBLIC_URL + "/icons/mailWhiteIcon.svg"} alt="Socials" />
            </div>
            <div className="footer__document">
              <Link to={"/contract"}>Почитати договір оферти</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
