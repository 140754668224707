import React, { useEffect, useRef } from "react";
import "../styles/Gallery.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/pagination";

const Gallery = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    // Это гарантирует, что ссылки обновляются после рендера.
    const updateSwiperNavigation = () => {
      if (navigationPrevRef.current && navigationNextRef.current) {
        document.querySelectorAll(".swiper-button-disabled").forEach((button) => {
          (button as HTMLElement).style.pointerEvents = "none"; // Чтобы избежать кликов на disabled кнопки.
        });
      }
    };
    updateSwiperNavigation();
  }, []);

  return (
    <div className="additional">
      <section className="gallery wrapper" id="pictures">
        <Swiper
          autoHeight={true}
          freeMode={true}
          grabCursor={true}
          spaceBetween={40}
          resistanceRatio={0}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            370: {
              slidesPerView: 1,
            },
            460: {
              slidesPerView: 1,
            },
            461: {
              slidesPerView: 2,
            },
          }}
          loop={true}
          pagination={true}
          modules={[Autoplay, Navigation, Pagination]}
          className="gallery__swiper"
          navigation={{
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}
          onBeforeInit={(swiper: any) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          {galleryData.map((el, index) => (
            <SwiperSlide className="gallery__slide" key={index}>
              <img src={process.env.PUBLIC_URL + el.img} alt="Pictures" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="gallery__btns">
          <button ref={navigationPrevRef}>
            <svg width="27" height="51" viewBox="0 0 27 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M25.8174 4.2169C26.043 3.97822 26.2193 3.69746 26.3363 3.39064C26.4534 3.08382 26.5088 2.75695 26.4996 2.42869C26.4903 2.10044 26.4164 1.77722 26.2822 1.47751C26.148 1.17779 25.9561 0.907447 25.7174 0.681902C25.4788 0.456357 25.198 0.280031 24.8912 0.162992C24.5844 0.0459535 24.2575 -0.00950656 23.9292 -0.000220698C23.601 0.00906517 23.2778 0.0829147 22.978 0.217112C22.6783 0.351308 22.408 0.543224 22.1824 0.781902L0.932437 23.2819C0.493596 23.7461 0.249081 24.3606 0.249081 24.9994C0.249081 25.6382 0.493596 26.2527 0.932437 26.7169L22.1824 49.2194C22.4065 49.4633 22.6768 49.6603 22.9776 49.7988C23.2784 49.9374 23.6038 50.0148 23.9347 50.0265C24.2657 50.0383 24.5958 49.9842 24.9057 49.8673C25.2155 49.7505 25.4991 49.5732 25.7399 49.3458C25.9807 49.1184 26.174 48.8454 26.3084 48.5427C26.4428 48.2401 26.5157 47.9137 26.5229 47.5826C26.5301 47.2514 26.4715 46.9222 26.3504 46.6139C26.2293 46.3057 26.0481 46.0246 25.8174 45.7869L6.18744 24.9994L25.8174 4.2169Z"
                fill="black"
              />
            </svg>
          </button>
          <button ref={navigationNextRef}>
            <svg width="27" height="51" viewBox="0 0 27 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.18262 46.7831C0.957075 47.0218 0.78075 47.3025 0.663712 47.6094C0.546673 47.9162 0.491211 48.2431 0.500498 48.5713C0.509783 48.8996 0.583633 49.2228 0.717831 49.5225C0.852028 49.8222 1.04394 50.0926 1.28262 50.3181C1.5213 50.5436 1.80206 50.72 2.10888 50.837C2.41571 50.954 2.74258 51.0095 3.07083 51.0002C3.39909 50.9909 3.7223 50.9171 4.02201 50.7829C4.32173 50.6487 4.59208 50.4568 4.81762 50.2181L26.0676 27.7181C26.5065 27.2539 26.751 26.6394 26.751 26.0006C26.751 25.3618 26.5065 24.7473 26.0676 24.2831L4.81762 1.78061C4.59356 1.5367 4.32328 1.33974 4.02246 1.20118C3.72164 1.06262 3.39629 0.985214 3.06531 0.973457C2.73432 0.961697 2.4043 1.01582 2.0944 1.13269C1.78451 1.24955 1.50093 1.42682 1.26013 1.65421C1.01933 1.8816 0.826105 2.15456 0.69169 2.45725C0.557274 2.75994 0.484343 3.08633 0.477131 3.41745C0.469921 3.74856 0.528572 4.07781 0.649683 4.38607C0.770792 4.69432 0.951948 4.97544 1.18262 5.2131L20.8126 26.0006L1.18262 46.7831Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Gallery;

const galleryData = [
  {
    id: 0,
    img: "/img/4.png",
  },
  {
    id: 1,
    img: "/img/5.png",
  },
  {
    id: 2,
    img: "/img/4.png",
  },
  {
    id: 3,
    img: "/img/5.png",
  },
  {
    id: 4,
    img: "/img/4.png",
  },
  {
    id: 5,
    img: "/img/5.png",
  },
];
